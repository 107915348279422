// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-exhibition-template-js": () => import("/opt/build/repo/src/templates/exhibition-template.js" /* webpackChunkName: "component---src-templates-exhibition-template-js" */),
  "component---src-templates-post-template-js": () => import("/opt/build/repo/src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("/opt/build/repo/src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("/opt/build/repo/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-studio-notes-js": () => import("/opt/build/repo/src/pages/studio-notes.js" /* webpackChunkName: "component---src-pages-studio-notes-js" */)
}

